/* eslint-disable max-len */
'use client';
import { useState } from 'react';
import Link from 'next/link';
import { SignedIn, SignedOut, UserButton } from '@clerk/nextjs';
import { usePathname, useRouter } from 'next/navigation';
import { ChevronDownIcon } from '../icons/ChevronDownIcon';
import { Button, Input } from '@nextui-org/react';
import { SearchIcon } from '../icons/SearchIcon';
import Logo from '../svgs/Logo';
import AnnouncementBar from '../ui/announcement-bar';

const Header = ({ isBusinessPath, ...props }: { isBusinessPath: boolean; className: string }) => {
    // const { isLoaded, isSignedIn, user } = useUser();
    const router = useRouter();

    const [showMenu, setShowMenu] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    const toggleMenu = () => {
        if (window.innerWidth < 1024) {
            setShowMenu(!showMenu);
        } else {
            setShowMenu(false);
        }
    };

    const pathname = usePathname();
    const [isIntOpen, setIsIntOpen] = useState(false);

    const toggleInternationalization = () => {
        setIsIntOpen(!isIntOpen);
    };

    const handleSearch = () => {
        toggleMenu();
        router.push(`/companies?q=${searchInput}&page=1&verified=false&trustScoreRange=All&entityStatusType=All`);
        setSearchInput('');
    };

    // useEffect(() => {
    //     if (isSignedIn) {
    //         user.reload();
    //         console.log('User is signed in');
    //         // router.push('/');
    //     } else {
    //         console.log('User is not signed in');
    //     }
    // }, [isSignedIn, user]);

    // if (!isLoaded) {
    //     return <Loading />;
    // }
    return (
        <header className={`sticky top-0 z-50 duration-300 ${props.className} bg-white border-b border-gray-200`}>
            {isBusinessPath && <AnnouncementBar animate /> }
            <div className="container">
                <nav className="flex items-center justify-between py-1 lg:py-0">
                    <Link prefetch={true} href="/" className="justify-start items-center w-40">
                        <Logo/>
                    </Link>
                    {isBusinessPath ? (
                        <div className="flex items-center justify-center">
                        <div onClick={() => toggleMenu()} className={`overlay fixed inset-0 z-[51] bg-black/60 lg:hidden ${showMenu ? '' : 'hidden'}`}></div>
                        <div className={`menus ${showMenu ? 'overflow-y-auto ltr:!right-0 rtl:!left-0' : ''}`}>
                            <div className="border-b border-gray/10 ltr:text-right rtl:text-left lg:hidden">
                                <button onClick={() => toggleMenu()} type="button" className="p-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="h-6 w-6 text-black dark:text-white"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <ul onClick={() => toggleMenu()}>
                                <li className="relative">
                                    <Link prefetch={false} href="/business/pricing" className={`${pathname?.includes('/business/pricing') ? 'active' : ''}`}>
                                        Pricing
                                    </Link>
                                </li>
                                <li>
                                    <Link prefetch={false} href="/business" className="">
                                        <Button size="sm" color="primary" className='text-white font-bold'>
                                        For businesses
                                        </Button>
                                    </Link>
                                </li>
                                <li>
                                    {/* <div className="relative">
                                        <select className="rounded-md px-1 py-1" onSelect={(e) => e.preventDefault()}>
                                            <option value="en">En</option>
                                        </select>
                                    </div> */}
                                    <div className="relative inline-block text-left">
                                        <button
                                            type="button"
                                            className="hover:bg-primary inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-bold focus:outline-none"
                                            id="options-menu"
                                            aria-expanded="true"
                                            aria-haspopup="true"
                                            onClick={((e) => {
                                                e.stopPropagation();
                                                toggleInternationalization();
                                            })}
                                        >
                                            En
                                            <ChevronDownIcon className="-mr-1 ml-2 self-center h-3 w-3" aria-hidden="true" />
                                        </button>

                                        {isIntOpen && (
                                            <div
                                                className="absolute right-0 mt-2 w-16 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                role="menu"
                                                aria-orientation="vertical"
                                                aria-labelledby="options-menu"
                                            >
                                                <div className="py-1" role="none">
                                                    <Link
                                                        href="/"
                                                        locale="en"
                                                        className="text-white hover:bg-primary-100 block px-4 py-2 text-sm"
                                                        role="menuitem"
                                                    >
                                                        En
                                                    </Link>
                                                    {/* Add more languages here when needed */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <button
                            type="button"
                            className="flex h-10 w-10 items-center justify-center rounded-full bg-primary lg:hidden"
                            onClick={() => toggleMenu()}
                        >
                            <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-white">
                                <path
                                    d="M2 15H11C11.552 15 12 15.447 12 16C12 16.553 11.552 17 11 17H2C1.448 17 1 16.553 1 16C1 15.447 1.448 15 2 15Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M2 8H20C20.552 8 21 8.447 21 9C21 9.553 20.552 10 20 10H2C1.448 10 1 9.553 1 9C1 8.447 1.448 8 2 8Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M21 2C21 1.447 20.552 1 20 1H7C6.448 1 6 1.447 6 2C6 2.553 6.448 3 7 3H20C20.552 3 21 2.553 21 2Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                    </div>
                    ) : (
                    <div className="flex items-center justify-center">
                        <div onClick={() => toggleMenu()} className={`overlay fixed inset-0 z-[51] bg-black/60 lg:hidden ${showMenu ? '' : 'hidden'}`}></div>
                        <div className={`menus ${showMenu ? 'overflow-y-auto ltr:!right-0 rtl:!left-0' : ''}`}>
                            <div className="border-b border-gray/10 ltr:text-right rtl:text-left lg:hidden">
                                <button onClick={() => toggleMenu()} type="button" className="p-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="h-6 w-6 text-black dark:text-white"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <ul onClick={() => toggleMenu()}>
                                {/* <li>
                                    <div className="relative">
                                        <input type="text" placeholder="Search" className="rounded-md border px-2 py-1" />
                                        <button type="button" className="absolute right-2 top-1/2 -translate-y-1/2 transform">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="h-5 w-5"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                </li> */}
                                {/* <li className="relative hidden items-center lg:inline-flex">
                                    <button type="button" onClick={() => toggleSearch()} className=" hover:text-primary">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.7363 17.4637L14.6512 13.3785C15.6799 12.0753 16.3 10.4347 16.3 8.65C16.3 4.4317 12.8683 1 8.65 1C4.4317 1 1 4.4317 1 8.65C1 12.8683 4.4317 16.3 8.65 16.3C10.4356 16.3 12.0754 15.6808 13.3786 14.6512L17.4637 18.7363C17.6392 18.9118 17.8696 19 18.1 19C18.3304 19 18.5608 18.9118 18.7363 18.7363C19.0882 18.3844 19.0882 17.8156 18.7363 17.4637ZM2.8 8.65C2.8 5.4244 5.4244 2.8 8.65 2.8C11.8756 2.8 14.5 5.4244 14.5 8.65C14.5 11.8756 11.8756 14.5 8.65 14.5C5.4244 14.5 2.8 11.8756 2.8 8.65Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </button>
                                </li> */}
                                <li className={`search-bar overflow-hidden transition-all duration-500 ltr:right-0 block !w-full`} onClick={(e) => e.stopPropagation()}>
                                    <Input
                                        classNames={{
                                            base: 'sm:max-w-[100%]',
                                            inputWrapper: 'border-1  w-full min-h-[40px]',
                                        }}
                                        placeholder="Search a company"
                                        size="sm"
                                        endContent={
                                            <SearchIcon
                                                className="cursor-pointer text-xl"
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    handleSearch();
                                                }}
                                                onTouchEnd={(e: any) => {
                                                    e.preventDefault();
                                                    handleSearch();
                                                }}
                                            />
                                        }
                                        type="text"
                                        onChange={(e) => setSearchInput(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleSearch();
                                            }
                                        }}
                                    />
                                    {/* <form action={onSubmit} className="relative">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="w-full border-b border-white bg-transparent py-1 outline-none ltr:pl-2 ltr:pr-8 rtl:pl-8 rtl:pr-2"
                                        />
                                        <button
                                            type="button"
                                            className="absolute top-1/2 -translate-y-1/2 hover:text-primary ltr:right-0 rtl:left-0"
                                            onClick={() => toggleSearch()}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="h-6 w-6"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </form> */}
                                </li>
                                {/* Featured posts */}
                                <li className="relative">
                                    <Link prefetch={false} href="/featured-posts" className={`${pathname?.includes('/featured-posts') ? 'active' : ''}`}>
                                        Featured Posts
                                    </Link>
                                </li>
                                <li className="relative">
                                    <Link prefetch={false} href="/report-a-scam" className={`${pathname?.includes('/report-a-scam') ? 'active' : ''}`}>
                                        Report a Scam
                                    </Link>
                                </li>

                                <li className="relative items-center rtl:pr-9 rtl:before:-right-[2px] lg:inline-flex">
                                    <SignedOut>
                                        <Link prefetch={false} href="/login">
                                            {/* <Button size="sm" color="primary" className='text-white font-bold'> */}
                                                Login
                                            {/* </Button> */}
                                        </Link>
                                    </SignedOut>
                                    <SignedIn>
                                        <UserButton />
                                    </SignedIn>
                                </li>
                                <li>
                                    <Link prefetch={false} href="/business" className="">
                                        <Button size="sm" color="primary" className='text-white font-bold'>
                                        For businesses
                                        </Button>
                                    </Link>
                                </li>
                                <li>
                                    {/* <div className="relative inline-block text-left">
                                        <button
                                            type="button"
                                            className="text-gray-700 hover:bg-primary inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-bold focus:outline-none"
                                            id="options-menu"
                                            aria-expanded="true"
                                            aria-haspopup="true"
                                            onClick={((e) => {
                                                e.stopPropagation();
                                                toggleInternationalization();
                                            })}
                                        >
                                            En
                                            <ChevronDownIcon className="-mr-1 ml-2 self-center h-3 w-3" aria-hidden="true" />
                                        </button>

                                        {isIntOpen && (
                                            <div
                                                className="absolute right-0 mt-2 w-16 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                role="menu"
                                                aria-orientation="vertical"
                                                aria-labelledby="options-menu"
                                            >
                                                <div className="py-1" role="none">
                                                    <Link
                                                        href="/"
                                                        locale="en"
                                                        className="text-gray-700 hover:bg-gray-100 block px-4 py-2 text-sm"
                                                        role="menuitem"
                                                    >
                                                        En
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                    </div> */}
                                </li>
                            </ul>
                        </div>
                        <button
                            type="button"
                            className="flex h-10 w-10 items-center justify-center rounded-full bg-primary lg:hidden"
                            onClick={() => toggleMenu()}
                        >
                            <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-white">
                                <path
                                    d="M2 15H11C11.552 15 12 15.447 12 16C12 16.553 11.552 17 11 17H2C1.448 17 1 16.553 1 16C1 15.447 1.448 15 2 15Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M2 8H20C20.552 8 21 8.447 21 9C21 9.553 20.552 10 20 10H2C1.448 10 1 9.553 1 9C1 8.447 1.448 8 2 8Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M21 2C21 1.447 20.552 1 20 1H7C6.448 1 6 1.447 6 2C6 2.553 6.448 3 7 3H20C20.552 3 21 2.553 21 2Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </button>
                    </div>
                    )}
                </nav>
            </div>
        </header>
    );
};

export default Header;
